import ObjVrScenes from "../../../shared/models/obj_vr_scene";
import * as actions from "./actions";
import * as getters from "./getters";
import * as mutations from "./mutations";

const state = {
  modelClass: ObjVrScenes,
  baseUrl: "/api/web/member/obj_vr_scenes",
  resources: [],
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
